let deferredPrompt = null;

export const initInstallPrompt = (callback) => {
  window.addEventListener('beforeinstallprompt', (e) => {
    e.preventDefault();
    deferredPrompt = e;
    if (callback) callback(true); // 설치 버튼 표시를 위한 콜백
  });

  window.addEventListener('appinstalled', () => {
    console.log('App was installed');
    if (callback) callback(false); // 설치 버튼 숨기기
  });
};

export const triggerInstallPrompt = async () => {
  if (deferredPrompt) {
    deferredPrompt.prompt();
    const choiceResult = await deferredPrompt.userChoice;
    deferredPrompt = null;
    return choiceResult.outcome === 'accepted';
  }
  return false;
};
